// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tasksTable-finalprod",
            "region": "us-east-1"
        },
        {
            "tableName": "animeTable-finalprod",
            "region": "us-east-1"
        },
        {
            "tableName": "groceriesTable-finalprod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:c5fbca70-7c7e-4533-bd45-f671f1963f52",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UxGTEApmJ",
    "aws_user_pools_web_client_id": "2m43mndj7euj3abqhl58ig9msr",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "tasksApi",
            "endpoint": "https://sfd1279w1a.execute-api.us-east-1.amazonaws.com/finalprod",
            "region": "us-east-1"
        },
        {
            "name": "animeApi",
            "endpoint": "https://cnzqdxwwba.execute-api.us-east-1.amazonaws.com/finalprod",
            "region": "us-east-1"
        },
        {
            "name": "groceriesApi",
            "endpoint": "https://rzupx4j5d0.execute-api.us-east-1.amazonaws.com/finalprod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
