// ./react-redux-client/src/components/GroceryAddForm.js
import React from 'react';
import { FormGroup, Label, Input ,Button, Container, Row  } from 'reactstrap';


export const GroceryAddForm = (props) => {
  // let availableUsers = props.groceries;
  // let result = availableUsers.map(names => names.createdBy);
  return (
    <form className="form form-horizontal" id="GroceryAddForm" onSubmit={props.submitAddGrocery}>
      <h3 className="centerAlign">Add Your Grocery</h3>
          <FormGroup>
            
            <Label> Grocery: </Label>
              <Input
                type="text" placeholder="Enter grocery"
                name ="groceryName"
              />

              <br></br>   

            <Label> Quantity: </Label>
              <Input
                  type="number" placeholder="Enter quantity"
                  name ="quantity"
              />
          
                
              <br></br>   

             {/* <Label> Assign To: </Label>
              {result.length !== 0 && <Input type="select" name="assignedTo">
                {result.map((names, i) => 
                      <option key={i}>{names}</option>
                  )} 
              </Input>}

                <br></br>    */}

              <Label> Assign To: (Leave Blank to assign to Self) </Label>
              <Input type="text" name="assignedTo" placeholder="Enter Assignee Name"></Input>

              {/* {result.length !== 0 && <Input type="text" name="assignedTo" placeholder="Enter quanity"> </Input>} */}
                
              
              






          </FormGroup>
            
          <br></br>
          <FormGroup>
             <Container>
               <Row>             
                <Button type="submit" color="primary" size="medium"> Submit</Button>
              </Row>
            </Container>
          </FormGroup>
    </form>
  );
}
export default GroceryAddForm;