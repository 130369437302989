import React, { Component } from 'react';
import PropTypes from 'prop-types'
//import { Link } from 'react-router';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../App.css';



//const hello = "hi";

export default class CarouselComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    //this.isAnimating = this.isAnimating.bind(this);
   
  }

  // isAnimating(isActive) {
  //   const interval = isActive ? 3000 : 0;
  //   console.log(interval);
  //   return interval;
    
  // }

  onExiting() {
    //normally set to true
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
 
  }


  
  render() {
    const { activeIndex } = this.state;
    const width = this.props.width;
    const isActive = this.props.isActive;
    const items = this.props.items
    

    const slides = items.map((item) => {
      return (
        
        
        <CarouselItem
          className="carousel-item"
          tag="div"
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
         
        >
        <Link to={item.link}>
          {/* <img src={item.src} alt={item.altText} width= {width.toString()} height="300"    /> */}
          <img id="carouselPic" src={item.src} alt={item.altText}    />
          <CarouselCaption className= "text-secondary" captionText={item.caption} captionHeader={item.captionHeader}/>
          </Link>
        </CarouselItem>
     
        
      );
    });

    return (
        <div>
        {/* <style>
        {
          `.carousel {
              height: 450px;  
              background: ${this.props.backgroundColor};
              max-width: ${this.props.width}px;
              width: ${this.props.width}px;
             
            }`
        }
      </style> */}
      <h3>{this.props.hello}</h3>
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        interval = {isActive ? 5000 : 0}
   
        
      >

      {/* this.props.isActive affects whether the controls are active */}
        {isActive && 
        <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />}

        {slides}
        
        {isActive && 
        <CarouselControl className=".carousel-control-prev"  direction="prev" directionText="Previous" onClickHandler={this.previous} /> }
        
        {isActive && 
        <CarouselControl className=".carousel-control-next" direction="next" directionText="Next" onClickHandler={this.next} />}
      </Carousel>

      </div>
    );
  }

  
}


