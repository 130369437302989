import React, {Component} from 'react';
import { Progress } from 'reactstrap';
import { Link } from 'react-router-dom'


export default class HomeProgress extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      user:[]
    };

   






  }

    componentDidMount(){
    
    }

    taskColor(task){
      let status;
      let createdDate = new Date(task.submittedDate);
      createdDate = createdDate.getTime();
      let endDate = Date.now();
      
      const daysElapsed = (createdDate, endDate) => {
        
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          let totalDays = Math.round(Math.abs(endDate - createdDate) / millisecondsPerDay)
          return totalDays;
      }

      if(task.completedFlag){
          status = "success";  
      }
      else if(daysElapsed(createdDate, endDate) <= 2 && !task.completedFlag )
      {
          status = "info";
      }
      else if(daysElapsed(createdDate, endDate) >= 3 && daysElapsed(createdDate, endDate) <= 6 && !task.completedFlag)
      {
          status = "warning";
      }
      else if(daysElapsed(createdDate, endDate) > 6 && !task.completedFlag)
      {
          status = "danger";
      }
  
      return status;
  }

taskStatus(tasks, type) {

  
  let status = {success: 0, info: 0, warning: 0, danger: 0}

  //Get Status of Tasks and it's total number
for (const task of tasks) {
  switch(this.taskColor(task)) 
  {
    case "success": status.success ++; break;
    case "info": status.info ++; break;
    case "warning": status.warning++; break;
    case "danger": status.danger++; break;
    default: return null;
  }
}

//output of function based on progressbar
switch(type) 
{
  case "success": return status.success;
  case "info": return status.info;
  case "warning": return status.warning;
  case "danger": return status.danger;
  default: return null;
}

}

//Calculate current episode
calculateCurrentEpisode = (initDate) => {
    
  let currentDate = Date.now();
  let newInitDate = new Date(initDate);
  let diff = (new Date(currentDate).getTime() - new Date(newInitDate).getTime()) / 1000;
  diff /= (60 * 60 * 24 * 7);
  const episodeNumber = Math.abs(Math.round(diff));
  return episodeNumber;
}

//Total Count for each component
completedItems(array, arrayType) {

  let count = 0;
  switch(arrayType) {
    case "groceries":
      for (const item of array){
        if(item.purchased === true){
            count++;
        }
      }
    return count;

    case "tasks":
      for (const item of array){
        if(item.completedFlag === true){
            count++;
        }
      }
    return count;

    case "bookmarks":
      for (const item of array){
        if(item.episodesWatched === this.calculateCurrentEpisode(item.initDateReleased)){
            count++;
        }
      }
    return count;
    
    default:
      count = 0;
  }
}
  



  render() {
    const groceryType = "groceries";
    const bookmarksType = "bookmarks";
    const tasksType = "tasks";
  
  return (
   
    <div className="homeProgress">
      
      
      
      <Link to={`/tasks`} >
      <div className="text-center"><strong>Tasks Completed: {this.completedItems(this.props.tasks, tasksType )}/{this.props.tasks.length}</strong> </div>
        <Progress multi>
          <Progress animated bar color="success" value={this.taskStatus(this.props.tasks, "success" )} max={this.props.tasks.length}>{this.taskStatus(this.props.tasks, "success")}</Progress>
          <Progress animated bar value={this.taskStatus(this.props.tasks, "info")} max={this.props.tasks.length}>{this.taskStatus(this.props.tasks, "info")}</Progress>
          <Progress animated bar color="warning" value={this.taskStatus(this.props.tasks, "warning")} max={this.props.tasks.length}>{this.taskStatus(this.props.tasks, "warning")}</Progress>
          <Progress animated bar color="danger" value={this.taskStatus(this.props.tasks, "danger")} max={this.props.tasks.length}>{this.taskStatus(this.props.tasks, "danger")}</Progress>
        </Progress>
      </Link>

      <Link to={`/groceries`} >
        <div className="text-center"><strong>Groceries Purchased: {this.completedItems(this.props.groceries, groceryType)}/{this.props.groceries.length}</strong></div>
        <Progress animated value={this.completedItems(this.props.groceries, groceryType)} max={this.props.groceries.length}>
          {this.completedItems(this.props.groceries, groceryType)}
        </Progress> 
      </Link>

      <Link to={`/anime`} >
        <div className="text-center"><strong>Anime Watched This Week: {this.completedItems(this.props.bookmarks, bookmarksType)}/{this.props.bookmarks.length}</strong></div>
        <Progress animated value={this.completedItems(this.props.bookmarks, bookmarksType)} max={this.props.bookmarks.length}>
          {this.completedItems(this.props.bookmarks, bookmarksType)}
        </Progress> 
      </Link>








    </div>
    
    
  );
};

}
