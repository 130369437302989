import React, { Component } from "react";
import {ConfirmSignUp} from "aws-amplify-react";
import '../../App.css';
import logo from '../../images/icons/OtaskuLogo2.png'


  import { Alert, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Form, FormGroup, Label, Input} from 'reactstrap';


export default class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"]

    this.state = {
        showSampleCard: false,
        showConfirmedSampleCard: false
      }

   
  }

//     Auth.signIn(username, password)
//         .then(user => console.log(user))
//         .catch(err => console.log(err));


// forgotPasswordClick = () => this.changeState('forgotPassword');
returnSignIn = () => {
    this.changeState('signIn');
    this.setState({  showConfirmedSampleCard: false });
}

showSampleCard = () => this.setState(prevState => ({  
    showSampleCard: !prevState.showSampleCard
}));

showConfirmedSampleCard = () => this.setState(prevState => ({  
    showConfirmedSampleCard: !prevState.showConfirmedSampleCard,
    showSampleCard: !prevState.showSampleCard
}));

banishToEvil(){
    window.open('https://villains.fandom.com/wiki/Every_Villain_Is_Lemons', "_blank", "noopener noreferrer")
}
// showSampleCreds = () => this.setState(prevState => ({  
//     showSampleCreds: !prevState.showSampleCreds
// }));


// handleClickShowPassword = () => {
//     this.setState(state => ({ showPassword: !state.showPassword }));
//   };


showComponent(theme) {
    let showSampleCard = this.state.showSampleCard;
    let showConfirmedSampleCard = this.state.showConfirmedSampleCard;
    //let showSampleCreds= this.state.showSampleCreds;

    return (

    <div className="signInComponent">
          
    {   
        !showSampleCard && !showConfirmedSampleCard && <Card>
            <CardImg top width="100%" src={logo} alt="Card image cap" />
            <CardBody className="cardTextLong">
            <CardTitle className="text-center"><strong>Sign Up Verification</strong></CardTitle>
            <CardText>  
                An email has been sent to the Admin! If your request is approved, a follow-up email will be sent to you. 
                If you made a mistake during signup (Wrong Email, etc), feel free to try with another account, or reach out to the Admin personally. 
                In the meantime, check out the <a href="#sampleCard" onClick={this.showSampleCard}>sample account</a>!
            </CardText> 
                <a href="#returnedSignIn" onClick={this.returnSignIn}>Return to SignIn Screen</a>   
            </CardBody>
            </Card>
    } 

    {       showSampleCard && !showConfirmedSampleCard && 
        <Card>
            <CardImg top width="100%" src={logo} alt="Card image cap" />
            <CardBody className="cardTextLong">
            <CardTitle className="text-center"><strong>Sign In</strong></CardTitle>
            <CardText>  
             Are you Evil?
            </CardText>
            <ButtonGroup>
                <Button  type="button" color="success" size="sm" onClick={this.banishToEvil}>
                   Yes
                </Button>
                <Button  type="button" color="success" size="sm" onClick={this.showConfirmedSampleCard} >No</Button>
            </ButtonGroup>
            </CardBody>
            </Card>   
    } 

    {       showConfirmedSampleCard && !showSampleCard && 
        <Card>
            <CardImg top width="100%" src={logo} alt="Card image cap" />
            <CardBody className="cardTextLong">
            <CardTitle className="text-center"><strong>Not EVIL Confirmed!</strong></CardTitle>
            <CardSubtitle>Congrats on not being EVIL! (They're cray...) </CardSubtitle>
            <br></br>
            <CardText>  
                Sample Account Username: <strong>sampleUser</strong>
                <br></br>
                Sample Password: <strong>NotEvil!10</strong>
            </CardText>
                <a href="#returnedSignUp" onClick={this.returnSignIn}>Return to Sign In Screen</a>  
            </CardBody>
            </Card>   
    } 

</div>


    );
  }
}


    

















