// ./react-redux-client/src/components/Groceries.js
import React from 'react';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import uuid from 'uuid';
import { API, Auth } from "aws-amplify";
import '../../App.css'; 
import { GroceryEditForm } from './GroceryEditForm';
import { GroceryAddForm } from './GroceryAddForm';


export default class Groceries extends React.PureComponent {
  constructor(props){
    super(props);

    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideEditModal = this.hideEditModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);

    this.state = {
        groceries:[
          // {
          //   "quantity": "1",
          //   "groceryName": "Milk",
          //   "groceriesId": "45bd8440-87b1-11e9-8cbc-0db2af470f25",
          //   "purchased": false,
          //   "createdBy": "testUser",
          //   "assignedTo": "testUser"
          // },
          // {
          //   "quantity": "2",
          //   "groceryName": "eggs",
          //   "groceriesId": "063f7ce0-791c-11e9-a6a8-67b5172612c2",
          //   "purchased": false,
          //   "createdBy": "testUser",
          //   "assignedTo": "kevin"
          // },
          // {
          //   "quantity": "3",
          //   "groceryName": "test",
          //   "groceriesId": "af7e28c0-87b2-11e9-9724-1398fd9a5d07",
          //   "purchased": false,
          //   "createdBy": "testUser",
          //   "assignedTo": "kevin"
          // },
          // {
          //   "quantity": "1",
          //   "groceryName": "cheese",
          //   "groceriesId": "84645610-72c6-11e9-a476-27b8ba2340fe",
          //   "purchased": true,
          //   "createdBy": "testUser",
          //   "assignedTo": "kevin"
          // },
          // {
          //   "quantity": "1",
          //   "groceryName": "cake",
          //   "groceriesId": "730d78f0-8735-11e9-9b95-231d91f6e2b3",
          //   "purchased": false,
          //   "createdBy": "testUser",
          //   "assignedTo": "testUser"
          // },
          // {
          //   "quantity": "1",
          //   "groceryName": "test",
          //   "groceriesId": "5db86430-87b0-11e9-b346-4129f1c70640",
          //   "purchased": true,
          //   "createdBy": "testUser",
          //   "assignedTo": "testUser"
          // }
        ],
        grocery:null,
        isFetching: false,
        error: null,
        successMsg:null,
        showDeleteModal: false,
        groceryToDelete: null,
        showEditModal: false,
        groceryToEdit: null,
        showAddModal:false,
        createdBy:null,
        purchased:false,
        assignedTo: null,
        dropdownOpen: false
    }
  }
componentDidMount(){
   
    
    //this.fetchUser();
    this.fetchList();
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

/////// Fetch User Creds ///////
  
  // async fetchUser() {
  //   let user = await Auth.currentAuthenticatedUser()
  //   this.setState({ createdBy: user.username });
  // }




///// Get Request/////

async fetchList() {

  let user = await Auth.currentAuthenticatedUser()
  this.setState({ createdBy: user.username });

  let myInit = { 
    headers: {}, 
    response: false, 
    queryStringParameters: {  
        createdBy: user.username
    }
  }

  await API.get("groceriesApi", "/groceries", myInit)
  .then((res) => {
    this.setState({ groceries: [...res] });
    console.log(res)
    }).catch(err =>{
      console.log(err + err.data)
    });
 
}

///Add

  toggleAddGrocery(e){
    e.preventDefault();
  }
  
  hideAddModal(){
    this.setState({ showAddModal:false });
  }
  
  showAddModal(){
    this.setState({ showAddModal:true });
  }

  submitAddGrocery = async event => {
    event.preventDefault();
    
    const addForm = document.getElementById("GroceryAddForm");
    let groceries = this.state.groceries;
    const groceryName = addForm.groceryName.value;
    const quantity = addForm.quantity.value;
    const createdBy = this.state.createdBy;
    const groceriesId = uuid();
    let assignedTo;

    // Determining assignedTo value //
    if(addForm.assignedTo.value === "")
    {
    assignedTo = createdBy;
    }
    else  assignedTo = addForm.assignedTo.value;

    let newGrocery = {
      groceriesId : groceriesId, 
      groceryName : groceryName, 
      quantity : quantity, 
      createdBy : this.state.createdBy, 
      assignedTo : assignedTo
    }

    if(quantity === "" || groceryName === ""){
      alert("Please Fill All Required Field");
    }
    else 
    {
        await API.post("groceriesApi", "/groceries", {
            body: {
              groceriesId: newGrocery.groceriesId,
              groceryName: newGrocery.groceryName,
              quantity: newGrocery.quantity,
              createdBy: newGrocery.createdBy,
              purchased: false,
              assignedTo: newGrocery.assignedTo
            }
          })
            .then((res) => {
              this.setState({groceries : [...groceries, newGrocery]})
            }).catch(err =>{
              //console.log(err)
            });
          this.hideAddModal();
    }
  }
  //Set Purchased

  submitTogglePurchase = async (grocery) => {
    const groceryList = this.state.groceries;

        await API.put("groceriesApi", "/groceries", {
          body: {
            groceriesId: grocery.groceriesId,
            groceryName: grocery.groceryName,
            quantity: grocery.quantity,
            assignedTo:  grocery.assignedTo,
            createdBy: grocery.createdBy,
            purchased: !grocery.purchased
          }
          }).then((res) => {
            let newList = groceryList.map(groceryItem => {
            if(groceryItem.groceriesId === grocery.groceriesId){
                groceryItem.purchased = !groceryItem.purchased
                return groceryItem;
            }
            else return groceryItem;
            });
          
            this.setState({groceries: newList});
          }).catch(err =>{
            console.log(err)
          });
          this.fetchList();
    }

//Edit

showEditModal(groceryToEdit){
  this.setState(
    { 
      showEditModal:true,
      groceryToEdit:groceryToEdit,
    });
 }

hideEditModal(){
  this.setState({ showEditModal:false });
 }

submitEditGrocery = async event => {
  event.preventDefault();
  const editForm = document.getElementById('EditGroceryForm');
 
  let groceryList = this.state.groceries;
  const purchased = editForm.purchased.value;
  const groceryName = editForm.groceryName.value;
  const quantity = editForm.quantity.value;
  let assignedTo = editForm.assignedTo.value;
  const groceriesId = editForm.id.value;
  const createdBy = editForm.createdBy.value;

  //Necessary function to keep 'purchased' as boolean and not string
  const boolPurchased = value => {
      if (value === 'true') {
        return true
      }
      if (value === 'false') {
        return false
      }
      return value
    }

    assignedTo === ""? assignedTo = createdBy : assignedTo = assignedTo;

       
    let updatedGrocery =
    { 
      groceriesId : groceriesId,
      groceryName :groceryName,
      quantity : quantity,
      createdBy : createdBy,
      assignedTo : assignedTo,
      purchased: boolPurchased(purchased)
    }

    if(quantity === "" || groceryName === ""){
      alert("Please Fill All Required Field");
    }
    else {
    await API.put("groceriesApi", "/groceries", {
          body: {
            groceriesId: updatedGrocery.groceriesId,
            groceryName: updatedGrocery.groceryName,
            quantity: updatedGrocery.quantity,
            assignedTo: updatedGrocery.assignedTo,
            createdBy: updatedGrocery.createdBy,
            purchased: updatedGrocery.purchased
          }
        })
        .then(()=>{
          let newList = groceryList.map(grocery => {
            if(grocery.groceriesId === updatedGrocery.groceriesId)
            {
              grocery = updatedGrocery;
              return grocery;
            }
              else return grocery;
          });
          this.setState({groceries: newList});
        })
        this.hideEditModal();
  }
  
}

//Delete
hideDeleteModal(){
   this.setState({ showDeleteModal: false})
 }

showDeleteModal(groceryToDelete){
  this.setState(
    { 
      showDeleteModal:true,
      groceryToDelete:groceryToDelete,
    });
 }

 submitDeleteGrocery = async () => {
  let groceryList = this.state.groceries;
  const groceryToDelete = this.state.groceryToDelete
  const newList = groceryList.filter(grocery => grocery.groceriesId !== groceryToDelete.groceriesId)
 
  await API.del("groceriesApi", "/groceries", {
    body: 
      {
        groceriesId: groceryToDelete.groceriesId,
        groceryName: groceryToDelete.groceryName
      }
    })
    .then(()=>{
      this.setState({ groceries: newList });  
    })
  this.hideDeleteModal();

 }

//Extras


render(){

    const groceryState = this.state;
    const groceries = groceryState.groceries;
    const editGrocery = groceryState.groceryToEdit;
    const addGrocery = groceryState.newGrocery;
  

    return(

      <div  className=".comBody">
      <h3 className="centerAlign">Groceries</h3>
      {!groceries && groceryState.isFetching &&
        <p>Loading groceries....</p>
      }
      {groceries.length <= 0 && !groceryState.isFetching &&
        <p style={{color: "#ffffff"}}>No Groceries Available. Add something to List here.</p>
      }
      {groceries && groceries.length > 0 && !groceryState.isFetching &&
      <table className="table groceriesTable">
      <thead>
       <tr>
         <th className="textCenter">Grocery</th>
         <th className="textCenter">Quantity</th>
         <th className="textCenter">Created By</th>
         <th className="textCenter">Assigned To</th>
         <th className="textCenter">Purchased</th>
         <th className="textCenter">Edit</th>
         <th className="textCenter">Delete</th>
        </tr>
      </thead>
      <tbody>
        {groceries.map((grocery,i) => 
        <tr key={i}>
          <td className="textCenter">{grocery.groceryName}</td>
          <td className="textCenter">{grocery.quantity}</td>
          <td className="textCenter">{grocery.createdBy}</td>
          {grocery.assignedTo === grocery.createdBy?<td className="textCenter">{grocery.assignedTo}</td>:<td id="assignedTo">{grocery.assignedTo}</td>}
          <td className="textCenter"><Button onClick={() => this.submitTogglePurchase(grocery)} color="success" size="xs">
          {grocery.purchased === true?"Yes":"No"}
          </Button></td>
          
          <td className="textCenter"><Button onClick={() => this.showEditModal(grocery)} color="info" size="xs">Edit</Button></td>
          <td className="textCenter"><Button onClick={() => this.showDeleteModal(grocery)} color="danger" size="xs">Delete</Button></td>
        </tr> )
      }
      </tbody>
      </table>
    }

 
  
    {/* <Button id="addButton" onClick={this.toggleAddGrocery} color="info" size="medium" >Add Grocery</Button> */}
    <Button id="addButton" onClick={() => this.showAddModal(groceries)} color="info" size="medium" >Add Grocery</Button>
    <br></br>
    <br></br>
    <br></br>

{/* ////////////////////////////////////////////////////////////////////////// */}


{/* Modal for adding grocery */}
    <Modal
      isOpen={groceryState.showAddModal}
      toggle={this.hideAddModal}
      container={this}
      className="contained-modal-title"
    >
      <ModalHeader>
       Add Your Grocery
      </ModalHeader>
      <ModalBody>
    <div className="col-md-12">


  {<GroceryAddForm submitAddGrocery={this.submitAddGrocery} groceries={groceries} /> }
  
  {addGrocery && groceryState.successMsg &&
      <Alert color="success"> Grocery <strong> {addGrocery.groceryName} </strong>{groceryState.successMsg}
      </Alert>
    }
    
    
    </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.hideAddModal}>Close</Button>
      </ModalFooter>
    </Modal>


  {/* /////////////////////////////////////////////*/}


{/* Modal for editing grocery */}
    <Modal
      isOpen={groceryState.showEditModal}
      toggle={this.hideEditModal}
      container={this}
      className="contained-modal-title"
    >
      <ModalHeader>
       Edit Your Grocery
      </ModalHeader>
      <ModalBody>
    <div className="col-md-12">

    {editGrocery  && <GroceryEditForm groceryData={editGrocery} editGrocery={this.submitEditGrocery} groceries={groceries} /> }

    {editGrocery  && groceryState.isFetching &&
      <Alert color="info">
        <strong>Updating...... </strong>
      </Alert>
    }
    {editGrocery  && !groceryState.isFetching && groceryState.error &&
      <Alert color="danger">
        <strong>Failed. {groceryState.error} </strong>
      </Alert>
    }
    {editGrocery  && !groceryState.isFetching && groceryState.successMsg &&
      <Alert color="success"> Grocery <strong> {editGrocery.groceryName} </strong>{groceryState.successMsg}
      </Alert>
    }
    </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.hideEditModal}>Close</Button>
      </ModalFooter>
    </Modal>


  {/* /////////////////////////////////////////////*/}

    {/* Modal for deleting grocery */}
    <Modal
    isOpen={groceryState.showDeleteModal}
    toggle={this.hideDeleteModal}
    container={this}
    className="contained-modal-title"
  >
    <ModalHeader> Delete This Grocery</ModalHeader>
    <ModalBody>
    {groceryState.groceryToDelete && !groceryState.error && !groceryState.isFetching &&
      <Alert color="warning">
 Are you sure you want to delete this <strong>{groceryState.groceryToDelete.groceryName} </strong> ?
</Alert>
    }
    {groceryState.groceryToDelete && groceryState.error &&
      <Alert color="warning">
 Failed. <strong>{groceryState.error} </strong>
</Alert>
    }
{groceryState.groceryToDelete && !groceryState.error && groceryState.isFetching &&
      <Alert color="success">
  <strong>Deleting.... </strong>
</Alert>
    }
{!groceryState.groceryToDelete && !groceryState.error && !groceryState.isFetching&&
      <Alert color="success"> <strong>{groceryState.successMsg} </strong> </Alert>
    }
    </ModalBody>
    <ModalFooter>
     {!groceryState.successMsg && !groceryState.isFetching &&
       <div>
       <Button onClick={this.submitDeleteGrocery}>Yes</Button>
       <Button onClick={this.hideDeleteModal}>No</Button>
       </div>
    }
    {groceryState.successMsg && !groceryState.isFetching &&
      <Button onClick={this.hideDeleteModal}>Close</Button>
    }
    </ModalFooter>
  </Modal>

      </div>
    );
  }
}