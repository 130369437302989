import React from "react";
import { SignIn, Greetings, ConfirmSignUp } from "aws-amplify-react";
import config from "./aws-exports";
import CustomSignIn  from "./components/authComponents/CustomSignIn";
import CustomConfirmSignUp  from "./components/authComponents/CustomConfirmSignUp";
import App from "./App";
import { Authenticator } from "aws-amplify-react/dist/Auth";

class AppWithAuth extends React.Component {
  constructor(props, context) {
    super(props, context);
  }


  

  render() {

  

    return (
      <div>
        <Authenticator hide={[SignIn, Greetings, ConfirmSignUp]} amplifyConfig={config}>
          <CustomSignIn />
          <CustomConfirmSignUp />
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;