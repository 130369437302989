import React, {Component} from 'react';
import { Col, Row, Jumbotron } from 'reactstrap';

import sagiri from '../images/sagiriSlide.png';
import anime from '../images/animeSlide.png';
import groceriesSlide from '../images/groceriesKannaSlide.png';
import CarouselComponent from '../components/sharedComponents/CarouselComponent';
import HomeProgress from '../components/home/HomeProgress';


export default class Home extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      tasks: [
        {taskId: "1", task: 'Finish Projects', submittedDate: '2019-05-04T19:31:38.514Z', owner: 'sampleUser', completedFlag: false},
        {taskId: "2", task: 'Watch Anime', submittedDate: '2019-06-12T19:31:38.514Z', owner: 'sampleUser', completedFlag: false},
        {taskId: "3", task: 'Write Story', submittedDate: '2019-06-09T19:31:38.514Z', owner: 'sampleUser', completedFlag: true},
        {taskId: "4", task: 'Sleep', submittedDate: '2019-06-13T19:33:38.514Z', owner: 'sampleUser', completedFlag: true},
        {taskId: "5", task: 'Sleep', submittedDate: '2019-06-08T19:33:38.514Z', owner: 'sampleUser', completedFlag: false}
      ],
      groceries: [
        {
          "quantity": "1",
          "groceryName": "Milk",
          "groceriesId": "45bd8440-87b1-11e9-8cbc-0db2af470f25",
          "purchased": true,
          "createdBy": "sampleUser",
          "assignedTo": "sampleUser"
        },
        {
          "quantity": "2",
          "groceryName": "eggs",
          "groceriesId": "063f7ce0-791c-11e9-a6a8-67b5172612c2",
          "purchased": false,
          "createdBy": "sampleUser",
          "assignedTo": "kevin"
        },
        {
          "quantity": "3",
          "groceryName": "test",
          "groceriesId": "af7e28c0-87b2-11e9-9724-1398fd9a5d07",
          "purchased": false,
          "createdBy": "sampleUser",
          "assignedTo": "kevin"
        },
        {
          "quantity": "1",
          "groceryName": "cheese",
          "groceriesId": "84645610-72c6-11e9-a476-27b8ba2340fe",
          "purchased": true,
          "createdBy": "sampleUser",
          "assignedTo": "kevin"
        },
        {
          "quantity": "1",
          "groceryName": "cake",
          "groceriesId": "730d78f0-8735-11e9-9b95-231d91f6e2b3",
          "purchased": true,
          "createdBy": "sampleUser",
          "assignedTo": "sampleUser"
        },
        {
          "quantity": "1",
          "groceryName": "test",
          "groceriesId": "5db86430-87b0-11e9-b346-4129f1c70640",
          "purchased": true,
          "createdBy": "sampleUser",
          "assignedTo": "sampleUser"
        }
      ],
      bookmarks: [
        {
            "title": "1",
            "initDateReleased": "10/01/2018",
            "url": "google.com",
            "episodes": 10,
            "episodesWatched": 0
        },
        {
            "title": "2",
            "initDateReleased": "10/01/2018",
            "url": "google.com",
            "episodes": 5,
            "episodesWatched": 3
        },
        {
            "title": "3",
            "initDateReleased": "10/01/2018",
            "url": "google.com",
            "episodes": 12,
            "episodesWatched": 12
            
        },
        {
            "title": "4",
            "initDateReleased": "10/01/2018",
            "url": "google.com",
            "episodes": 12,
            "episodesWatched": 12
        },
 
    ],
      isLoading: true
    };
  }

    componentDidMount(){
    }
  
  



    

render(){
    const width = 500;
    const background = "black";
    const items = [
        {
          src: sagiri,
          altText: 'Tasks with Sagiri',
          caption: "Let's get those tasks done!",
          captionHeader: 'Tasks with Sagiri',
          link: "/tasks"
        },
        {
          src: groceriesSlide,
          altText: 'Groceries with Kanna',
          caption: "In case you forget!",
          captionHeader: 'Groceries with Kanna',
          link: "/groceries"
        },
        {
          src: anime,
          altText: 'Anime Bookmarks',
          caption: "We'll help you manage your addicti-...I mean hobby",
          captionHeader: 'Bookmarks with Waifus',
          link: "/anime"
        }
       
      ];

    return(
        <div className="container">
            
            <Jumbotron id="jumbo">
            <h1 className="jumboGreeting">Welcome to the <strong style={{color:"#ff0000"}}>*Sample Account*</strong>!</h1>
            <p>Feel free to check out some of the app's features with *Limited* functionality. </p>
            </Jumbotron>


            <div id="Displays">
                    <Row>
                        <Col col="auto" >
                        <CarouselComponent items={items} width={width} isActive={true} backgroundColor={background}/>
                        </Col>

                        <Col col="auto">
                        
                          <HomeProgress groceries ={this.state.groceries} tasks={this.state.tasks} bookmarks={this.state.bookmarks} /> 
                        

                        {/* <HomeProgress groceries ={this.state.groceries} tasks={this.state.tasks} bookmarks={this.state.bookmarks} />  */}
                        </Col>
                    </Row>
                </div>
               
            <br></br>

           
           
          

           
























        </div>

        
       














        );


}



}

