import React from 'react';
import CarouselComponent from '../sharedComponents/CarouselComponent'
import sagiri from '../../images/sagiriSlide.png';
import noTasks from '../../images/sagiriMoods/sagiriHappyNoTask.jpg';
import fewTasks from '../../images/sagiriMoods/sagirihappy.jpg';
import angry from '../../images/sagiriMoods/angrys1.jpg'
import doorClosed from '../../images/sagiriMoods/doorclosed.png';
import '../../App.css'; 

export default class SagiriMoods extends React.Component {

componentDidMount(){
    console.log("Sagiri Component has mounted");

}

imageSW(taskTotal) {

  let currentI;

if(taskTotal === 0)
{
  currentI = noTasks;
}
else if(taskTotal >=1 && taskTotal <= 3)
{
  currentI = fewTasks;
}
else if(taskTotal >= 4 && taskTotal <= 7)
{
  currentI = angry;
}
else if(taskTotal >= 8)
{
 currentI = doorClosed;
}
 
else {
  currentI = sagiri;
}

return currentI;
}



render(){
   
    const background = "transparent";
    let currentImage;
    let taskTotal = this.props.tasks.length;
    currentImage = this.imageSW(taskTotal);

    const items = [
      {
        src: currentImage,
        link: null, 
        captionText: null

      }
     
     
    ];
  

    return(
        <div>
        <CarouselComponent items={items} isActive={false} backgroundColor={background}/>


        </div>
    )
}

}