import React, { Component } from "react";
import { SignIn } from "aws-amplify-react";
import CustomConfirmSignUp  from "./CustomConfirmSignUp";
import '../../App.css';
import logo from '../../images/icons/OtaskuLogo2.png'

  import { Alert, Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Form, FormGroup, Label, Input} from 'reactstrap';


export default class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn"]

    this.state = {
      showSampleCard: false,
      showConfirmedSampleCard: false

   
  }

//     Auth.signIn(username, password)
//         .then(user => console.log(user))
//         .catch(err => console.log(err));
  }

forgotPasswordClick = () => this.changeState('forgotPassword');
signUp = () => this.changeState('signUp');

sampleAccountWarp = () => this.setState(prevState => ({  
  showSampleCard: !prevState.showSampleCard
}));

showConfirmedSampleCard = () => this.setState(prevState => ({  
  showConfirmedSampleCard: !prevState.showConfirmedSampleCard,
  showSampleCard: !prevState.showSampleCard
}));

returnSignIn = () => {
  this.changeState('signIn');
  this.setState(prevState => ({  
      showConfirmedSampleCard: !prevState.showConfirmedSampleCard
  }));
}

banishToEvil(){
  window.open('https://villains.fandom.com/wiki/Every_Villain_Is_Lemons', "_blank", "noopener noreferrer")
}


// handleClickShowPassword = () => {
//     this.setState(state => ({ showPassword: !state.showPassword }));
//   };


showComponent(theme) {
  let showSampleCard = this.state.showSampleCard;
  let showConfirmedSampleCard = this.state.showConfirmedSampleCard;

  
    return (
       
  <div className="signInComponent" >
 
  {
     !showSampleCard && !showConfirmedSampleCard && <Card >
      <CardImg top width="100%" src={logo} alt="Card image cap" />
      <CardBody>
      <CardTitle className="text-center"><strong>Sign In</strong></CardTitle>
      
      <CardText>  
      <Form id="loginForm" onSubmit={this.onSubmitLogin}>
          <FormGroup>
              <Label for="username">Username (Case Sensitve)</Label>
              <Input type="username" name="username" id="username" placeholder="Enter Username" onChange={this.handleInputChange}/>
          </FormGroup>
          <FormGroup>
              <Label for="password">Password</Label>
              <Input type="password" name="password" id="password" placeholder="Enter Password"  onChange={this.handleInputChange} />
          </FormGroup>
          </Form>
      </CardText>
      <Button  type="submit" color="success" size="sm" onClick={this.signIn} >Login</Button>


      <br></br>
      <br></br>
      
    <Row>
      <Col col="auto" >
          <a href="#forgotPassword" onClick={this.forgotPasswordClick}>Forgot Password?</a>  
      </Col>

      <Col col="auto">
          <a href="#signUp" onClick={this.signUp}>No Account?</a>  
      </Col>
    </Row>

    </CardBody>
   <a href="#sampleAccount" onClick={this.sampleAccountWarp}>Here to check out the Sample account?</a>  
    
  </Card>

}

      {       showSampleCard && !showConfirmedSampleCard && 
      <Card>
      <CardImg top width="100%" src={logo} alt="Card image cap" />
      <CardBody className="cardTextLong">
      <CardTitle className="text-center"><strong>Sign In</strong></CardTitle>
      <CardText>  
       Are you Evil? 
      </CardText>
      <ButtonGroup>
          <Button  type="button" color="success" size="sm" onClick={this.banishToEvil}>
             Yes
          </Button>
          <Button  type="button" color="success" size="sm" onClick={this.showConfirmedSampleCard} >No</Button>
      </ButtonGroup>
      </CardBody>
      </Card>   
} 

{       showConfirmedSampleCard && !showSampleCard && <Card>
      <CardImg top width="100%" src={logo} alt="Card image cap" />
      <CardBody className="cardTextLong">
      <CardTitle className="text-center"><strong>Not EVIL Confirmed!</strong></CardTitle>
      <CardSubtitle>Congrats on not being EVIL! (They're cray...) </CardSubtitle>
      <br></br>
      <CardText>  
          Sample Account Username: <strong>sampleUser</strong>
          <br></br>
          Sample Password: <strong>NotEvil!10</strong>
      </CardText>
          <a href="#returnedSignUp" onClick={this.returnSignIn}>Return to Sign In Screen</a>  
      </CardBody>
      </Card>   
} 
  </div>
  
    

    );
  }
}


    

















