import React from 'react';
import { Col, Row, Modal, Button, ModalHeader, 
    ModalBody, ModalFooter, Alert, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import '../App.css'; 
import SagiriMoods from '../components/tasks/SagiriMoods';
import TaskList from '../components/tasks/TaskList';
import uuid from 'uuid';



export default class Tasks extends React.Component {
    constructor(props){
        super(props);
        this.showDeleteModal = this.showDeleteModal.bind(this);  
        this.toggleAdd = this.toggleAdd.bind(this);
        this.addTask = this.addTask.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        

    this.state = {
        tasks: [
            {taskId: "1", task: 'Finish Projects', submittedDate: '2019-05-04T19:31:38.514Z', owner: 'kevin', completedFlag: false},
            {taskId: "2", task: 'Watch Anime', submittedDate: '2019-06-12T19:31:38.514Z', owner: 'kevin', completedFlag: false},
            {taskId: "3", task: 'Write Story', submittedDate: '2019-06-09T19:31:38.514Z', owner: 'testUser1', completedFlag: true},
            {taskId: "4", task: 'Sleep', submittedDate: '2019-06-13T19:33:38.514Z', owner: 'testUser1', completedFlag: true},
            {taskId: "5", task: 'Sleep Some More', submittedDate: '2019-06-08T19:33:38.514Z', owner: 'testUser1', completedFlag: false}
            ],
        isLoading: false,
        showAddTask: false,
        showDeleteModal: false,
        disableDeleteModal: false,
        taskToDelete: null,
        taskToAdd: null,
        user: null
    }

}



componentDidMount(){
  
}


//Add Task//

toggleAdd() {
    this.setState({
        task: this.state.task,
        showAddTask: !this.state.showAddTask
       })
}

 addTask(task) {

   
    let currentDate = new Date(Date.now());
    let isoDate = new Date(currentDate).toISOString()
    let newTask =  {taskId: uuid(), task: task, submittedDate: isoDate, owner: 'sampleUser', completedFlag: false};
    this.setState({tasks: [...this.state.tasks, newTask]})
  
}

onSubmit(e) {
    e.preventDefault();
    const input = document.getElementById('inputBox');
    let data = input.value;
   
    
    if(data !== ""){
        this.addTask(data); 
        e.target.value = ""
       
    }
    else
    {
        alert("Please enter data!")
    } 
}

onKeyPress = (e) => {
    if(e.which === 13) {
      this.onSubmit(e);
    }
  }




// Delete Tasks
showDeleteModal(task) {
    this.setState({ 
        showDeleteModal: true,
        taskToDelete: task                                    
    })  
}

hideDeleteModal() {
    this.setState({ showDeleteModal: false})
 }


confirmDeleteTask = async () => {
   
    let tasks = this.state.tasks;
    let newList = tasks.filter(task => task.completedFlag === false);
    this.setState({tasks: newList});
    this.hideDeleteModal();
   
}

//Update Completed State///

updateStatus(checkedTask) {

    let tasks = this.state.tasks;
    let newList = tasks.map(task => {
        if(task.taskId === checkedTask.taskId){
            task.completedFlag = !task.completedFlag;
            return task;
        }
        else return task;
    });

    this.setState({tasks: newList})
        
}

//Child Callbacks//
childCallBack = (keyFromChild) => {
    this.showDeleteModal(keyFromChild);
}

childUpdate = (keyFromChild) => {
    this.updateStatus(keyFromChild);
}

render(){
    
    const taskState = this.state
    const tasks = this.state.tasks;
    
    return(
        <div className="comBody">
            <h2 className="App">Tasks</h2>
            <div>

                <div id="Displays">
                    <Row>
                        <Col col="auto" >
                        <SagiriMoods tasks = {tasks}  />
                        </Col>

                        <Col col="auto">
                        <TaskList tasks = {tasks} childCallBack={this.childCallBack} childUpdate={this.childUpdate}  />
                        </Col>
                    </Row>
                </div>
               
            <br></br>

               <div id="Displays">
                
                {
                    <div>
                        <Row>
                            <Col></Col>
                            <Col>
                                <InputGroup id="addTaskBox" >
                                <Input placeholder="Add New Task" type="text" name="task" id="inputBox" onKeyPress={this.onKeyPress.bind(this)} />
                                <InputGroupAddon addonType="append"><Button color="success" type="submit" onClick={this.onSubmit}   >Submit</Button></InputGroupAddon>
                                {/* <Button color="secondary" onClick={() => this.toggleAdd()}  >Close</Button> */}
                                </InputGroup>
                                
                            </Col>
                            <Col></Col>
                        </Row>
                       
                    </div>
                }

              
                </div>
            </div>
            {/* Modal for deleting grocery */}
            <Modal
                isOpen={taskState.showDeleteModal}
                toggle={this.hideDeleteModal}
                container={this}
                className="contained-modal-title"
            >
                <ModalHeader> Delete This Task?</ModalHeader>
                <ModalBody>
                {taskState.taskToDelete && !taskState.isLoading &&
                <Alert color="warning">
            Are you sure you want to delete this task: <strong>{taskState.taskToDelete.task} </strong> ?
            </Alert>
                }

            {!taskState.taskToDelete && !taskState.error && !taskState.isLoading &&
                <Alert color="warning"> <p>Clear <strong>ALL</strong> completed tasks?</p> </Alert>
                }
                </ModalBody>
                <ModalFooter>
                {!taskState.isLoading &&
                <div>
                <Button onClick={this.confirmDeleteTask}>Yes</Button>
                <Button onClick={this.hideDeleteModal}>No</Button>
                </div>
                }

                </ModalFooter>
            </Modal>
        </div>
    )
}

}