import React from 'react';
import { Alert, Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col} from 'reactstrap';
import axios from 'axios';

import uuidv1 from 'uuid/v1';
import { API, Auth } from "aws-amplify";
import '../../App.css'; 
import * as animeFunctions from './animeLibrary.js';

export default class Anime extends React.Component {
    constructor(props){
      super(props);
        this.showDeleteModal = this.showDeleteModal.bind(this);  
        //this.showDeleteAllModal = this.showDeleteAllModal.bind(this);  
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.deleteAnime = this.deleteAnime.bind(this);
        this.toggleAdd = this.toggleAdd.bind(this);
        this.quickToggle = this.quickToggle.bind(this);
        this.addAnime = this.addAnime.bind(this);
        this.showAddModal = this.showAddModal.bind(this);
        this.hideAddModal = this.hideAddModal.bind(this);
        //this.filteredApi = this.filteredApi.bind(this);
        //this.sortAnime = this.sortAnime.bind(this);
        //this.watchStatus = this.watchStatus.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        //this.groupAnime = this.groupAnime.bind(this);

        this.state = {
            anime: [
                // {
                //     "title": "1",
                //     "initDateReleased": "10/01/2019",
                //     "url": "google.com",
                //     "episodes": 10,
                //     "episodesWatched": "0"
                // },
                // {
                //     "title": "2",
                //     "initDateReleased": "10/01/2019",
                //     "url": "google.com",
                //     "episodes": 5,
                //     "episodesWatched": "3"
                // },
                // {
                //     "title": "3",
                //     "initDateReleased": "10/01/2019",
                //     "url": "google.com",
                //     "episodes": 10,
                //     "episodesWatched": 6
                    
                // },
                // {
                //     "title": "4",
                //     "initDateReleased": "10/01/2019",
                //     "url": "google.com",
                //     "episodes": 12,
                //     "episodesWatched": "12"
                // },
         
            ],
            isLoading: false,
            showDeleteModal: false,
            showAddModal: false,
            animeToAdd: null,
            animeToDelete: null,
            animeFromApi: [],
            apiIsLoading: false,
            showDeleteAllModal: false,
            bookmarkedBy: null
            //animeFromServer:[]
        }
    }

    componentDidMount(){
        this.fetchList();
    }


    //Get Bookmarks from Database
    async fetchList() {

        let user = await Auth.currentAuthenticatedUser()
        this.setState({ bookmarkedBy: user.username });

        let myInit = { 
            headers: {}, 
            response: false, 
            queryStringParameters: {  
                bookmarkedBy: user.username
            }
        }
            
        await API.get("animeApi", "/anime", myInit)
            .then((res) => {
                this.setState({ anime: [...res] });
                
            }).catch(err =>{
                console.log(err + err.data)
            });

    }

    //Get Anime List from Anime Api
    async getAnimeFromApi() {
        await axios.get(animeFunctions.animeSeasonApiUrl)
        .then(res => {
            console.log(res.data.anime);
            this.setState({animeFromApi: res.data.anime});
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    showDeleteAllModal() {
        this.setState({ 
            showDeleteAllModal:true,
            showDeleteModal:true
        });
    }
    
    showDeleteModal(anime) {
        this.setState({ 
            showDeleteModal:true,
            animeToDelete: anime                      
        });
    }
    
    hideDeleteModal() {
        this.setState({ showDeleteModal:false });
    }
    
    showAddModal() {
        this.setState({ showAddModal:true });
    }
    
    hideAddModal() {
        this.setState({ showAddModal:false });
    }
    
    async deleteAnime() {
        let animeList = this.state.anime;
        const animeToDelete = this.state.animeToDelete
        const newList = animeList.filter(anime => anime.title !== animeToDelete.title)

        await API.del("animeApi", "/anime", {
            body: {
                 bookmarkId: this.state.animeToDelete.bookmarkId,
                 title: this.state.animeToDelete.title
                 }
         })
         .then(()=>{
            this.setState({ anime: newList }); 
            this.hideDeleteModal();
         })
      
        
        
    }

    // async deleteAllAnime() {
    //     console.log("all booksmark deleted, never to be seen again!")
    //         await API.del("animeApi", "/anime/deleteAll", {
    //             body: {}
    //          });
    //          this.fetchList();
    //          this.hideDeleteModal();
            
    //     }
    

   
    
    
    async addAnime(anime) {
        let newList = {
            bookmarkId: uuidv1(),
            title: anime.title,
            initDateReleased: anime.airing_start,
            url: anime.url, 
            episodes: anime.episodes,
            episodesWatched: 0,
            bookmarkedBy: this.state.bookmarkedBy
        }

        await API.post("animeApi", "/anime", {
            body: 
            {
                bookmarkId:newList.bookmarkId,
                title: newList.title,
                initDateReleased: newList.initDateReleased,
                url: newList.url, 
                episodes: newList.episodes,
                episodesWatched: newList.episodesWatched,
                bookmarkedBy: newList.bookmarkedBy
            }
          })
            .then((res) => {
                this.setState({anime: [...this.state.anime, newList]})
            }).catch(err =>{
              console.log(err)
            });
         
          this.fetchList();
        
    }
    
    

    quickToggle() {
        this.setState(prevState => ({
            showAddModal: !prevState.showAddModal
          }));
    }
    
    
    toggleAdd() {
        const animeState = this.state.animeFromApi;
    
        if(animeState.length === 0 || animeState === null)
        {
           this.getAnimeFromApi();
           
            
           
        }
        this.quickToggle();
    }
    
    
    
    
    
    async updateStatus(anime, currentNumber) {
        let animeList = this.state.anime;

        await API.put("animeApi", "/anime", {
            body: {

                bookmarkId: anime.bookmarkId,
                title: anime.title,
                initDateReleased: anime.initDateReleased,
                url: anime.url, 
                episodes: anime.episodes,
                episodesWatched: currentNumber,
                bookmarkedBy: anime.bookmarkedBy
            }
            }).then((res) => {

                let newList = animeList.map(item => 
                    {
                    let currentEpisode = animeFunctions.calculateCurrentEpisode(item.initDateReleased)

                    if(item.title === anime.title){
                        item.episodesWatched = currentEpisode
                        return item;
                    }
                    else return item;
                });
                
                this.setState({anime: newList});      
            }).catch(err =>{
            });
            
    }




    render(){
        const animeState = this.state;
        const anime = animeState.anime;

        //Gets current Anime List from State via Backend
        const animeList = anime.map(value => value.title);
         
        //Imported Anime from API in state
        const animeFromApi = animeState.animeFromApi;
        return(
            <div className="comBody">
            <div> 
                <Row>
               
                <Col><h4 id="title">Anime Bookmarks</h4></Col>
                <Col> 
                     <ButtonGroup className="buttonGroup">
                    {/* <Button id="addButton" color="danger" onClick={()=>this.showDeleteAllModal()} size="sm">Delete All</Button>   */}
                    <Button id="addButton" color="success" onClick={this.toggleAdd} size="sm">Add</Button>

                    {/* Delete Imported Titles from Imported Animes Table */}   
                    {/* {anime.length!==0 && !animeState.apiIsLoading && !animeState.isLoading && <Button id="deleteButton" color="danger" onClick={()=>this.showDeleteModal()} size="sm">Delete All Bookmarks</Button>} */}
                    </ButtonGroup> 
                </Col>
                </Row>
            </div>
           
            
            <br></br>

           
            <div className="scrollTable">
            <Table id="animeList" striped size="sm">
                <thead>
                    <tr>
                        <th scope="row">Status</th>
                        <th>Anime Title</th>
                        <th className="textCenter">Episodes Watched</th>
                        <th className="textCenter">Delete</th>
                    </tr>
                </thead>

                 
                 
                <tbody>
                    {anime && anime.map((animeList, i) =>
                    <tr key={i}>
                        <td >
                            {animeFunctions.watchStatus(animeList.initDateReleased, animeList.episodes, animeList.episodesWatched) === "Completed" &&
                                <Button color="success" onClick={() => this.updateStatus(animeList, 0)}>
                                    {animeFunctions.watchStatus(animeList.initDateReleased, animeList.episodes, animeList.episodesWatched)}
                                </Button>}
                    
                            {animeFunctions.watchStatus(animeList.initDateReleased, animeList.episodes, animeList.episodesWatched) === "Watched" &&
                                <Button color="primary" onClick={() => this.updateStatus(animeList, 0)}>
                                    {animeFunctions.watchStatus(animeList.initDateReleased, animeList.episodes, animeList.episodesWatched)}
                                </Button>}     
                    
                            {animeFunctions.watchStatus(animeList.initDateReleased, animeList.episodes, animeList.episodesWatched) === "Unwatched" &&
                                <Button color="warning" onClick={() => this.updateStatus(animeList, animeFunctions.calculateCurrentEpisode(animeList.initDateReleased) )}>
                                {animeFunctions.watchStatus(animeList.initDateReleased, animeList.episodes, animeList.episodesWatched)}
                                </Button>}
                        </td>
                        <td>
                        <a id="animeLinks" href={animeList.url} target="_blank" rel="noopener noreferrer" >{animeList.title}</a>
                        </td>
                        <td>
                            {
                                // Episodes Watched / Current Episode / Estimated Episodes
                                `${animeList.episodesWatched > animeList.episodes? animeList.episodes : animeList.episodesWatched   }/
                               
                                ${animeFunctions.calculateCurrentEpisode(animeList.initDateReleased) > animeList.episodes? animeList.episodes === null? animeFunctions.calculateCurrentEpisode(animeList.initDateReleased) : animeList.episodes  : animeFunctions.calculateCurrentEpisode(animeList.initDateReleased)}
                            
                                (${animeList.episodes!==null? animeList.episodes : "?"})`
                            
                            
                            }
                        </td>                    
                        <td><Button id="deleteButton" onClick={() => this.showDeleteModal(animeList)} color="danger" size="xs">x</Button></td>
                        
                    </tr>
                    )}
                </tbody>
               
               

                <tfoot>
                    <tr>
                        <th>Status</th>
                        <th>Anime Title</th>
                        <th>Episodes Watched</th>
                        <th>Delete</th>
                    </tr>
                </tfoot>

            </Table>
       
            </div>
        
   {/*/////////////// Modal for Add Form*/}

            
   <Modal
    isOpen={animeState.showAddModal}
    toggle={this.toggleAdd}
    container={this}
    className="contained-modal-title"
  >
    <ModalHeader> Add Bookmarks</ModalHeader>
    <ModalBody>

     
       

   {animeFromApi.length!==0 && animeFromApi && 
   
   <Table>
        <thead>
            <tr>
                <th>Add</th>
                <th>Anime Name</th>
            </tr>
        </thead>
        <tbody>

        {!animeState.isLoading && animeFunctions.sortAnime(animeFromApi) && animeFunctions.sortAnime(animeFromApi).map((value, i) => 
             animeFunctions.filteredApi(value, animeList) && <tr key={i}>
             {animeFunctions.filteredApi(value, animeList) && <td ><Button onClick={() => this.addAnime(value)} color="success" size="xs">+</Button></td>}
             {animeFunctions.filteredApi(value, animeList) && <td > <a href={value.url} target="_blank" rel="noopener noreferrer" >{animeFunctions.filteredApi(value, animeList)}</a></td> }

             </tr>
        )}

        </tbody>
    </Table>

    }

    </ModalBody>
    <ModalFooter>
     {!animeState.isLoading &&
       <div>
       
        {/* Done Button */}
        <Button color="success" onClick={this.hideAddModal}>Done</Button>
       </div>
    }

   
    </ModalFooter>
   
    </Modal>


    {/* Modal for deleting bookmarks */}
    <Modal
    isOpen={animeState.showDeleteModal}
    toggle={this.hideDeleteModal}
    container={this}
    className="contained-modal-title"
  >
    <ModalHeader> Delete? </ModalHeader>
    <ModalBody>

        {/* Deleting Imported Anime From Imported Animes Table */}
    {animeState.showDeleteAllModal && !animeState.apiIsLoading && !animeState.animeToDelete && 
    <div><strong>Warning:</strong><p>This will delete all bookmarks. Are you sure?</p></div>}

        {/* Deleting Bookmark */}
    {animeState.animeToDelete && !animeState.isLoading &&
        <Alert color="warning">
            Are you sure you want to delete this bookmark: <strong>{animeState.animeToDelete.title} </strong> ?
        </Alert>
    }

        {/* Bookmark deleted */}
    {!animeState.showDeleteModal && !animeState.animeToDelete && !animeState.error && !animeState.isLoading &&
      <Alert color="success"> <strong>Success! </strong> </Alert>
    }
    </ModalBody>
    
    <ModalFooter>
        
     {
       animeState.showDeleteModal && !animeState.isLoading && animeState.animeToDelete &&
       <div>
       <Button onClick={this.deleteAnime}>Yes</Button>
       <Button onClick={this.hideDeleteModal}>No</Button>
       </div>
    }

     {
        anime.length!==0 && !animeState.isLoading && !animeState.animeToDelete && animeState.showDeleteAllModal &&
       <div>
       <Button onClick={()=> this.deleteAllAnime()}>Yes</Button>
       <Button onClick={this.hideDeleteModal}>No!!!!!!!!!!!</Button>
       </div>
    }


    </ModalFooter>
  </Modal>



        </div>
        );
    
}
}