// ./react-redux-client/src/components/GroceryEditForm.js
import React from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';

///

export const GroceryEditForm = (props) => {
  let availableUsers = props.groceries;
  let result = availableUsers.map(names => names.assignedTo);
  let filteredNames = [...new Set(result)];
  console.log(typeof(props.groceryData.purchased))

  return (
    <form className="form form-horizontal" id="EditGroceryForm" onSubmit={props.editGrocery}>
    <div className="row">
    <div className="col-md-12">
    <FormGroup>
    <input type="hidden" value={props.groceryData.groceriesId} name="id"/>
    <input type="hidden" value={props.groceryData.createdBy} name="createdBy"/>
    <input type="hidden" value={props.groceryData.purchased} name="purchased"/>
    

          <Label>Grocery: (Can not edit Grocery Name) </Label>
            <Input
              type="text" placeholder="Enter grocery"
              name="groceryName" defaultValue={props.groceryData.groceryName} disabled
            />

          <Label> Quantity: </Label>
            <Input
                type="number" placeholder="Enter quanity"
                name ="quantity" defaultValue={props.groceryData.quantity}
            />

          {/* <Label> Assigned To: </Label>
            <Input type="select" name="assignedTo" defaultValue= {props.groceryData.assignedTo}  >
                {filteredNames.map((names, i) => 
                      <option key={i}>{names}</option>
                )}
            </Input> */}

          <Label> Assign To: (Leave Blank to assign to Self) </Label>
              <Input type="text" name="assignedTo" placeholder="Enter Assignee Name" defaultValue={props.groceryData.assignedTo}></Input>
   
          <br></br>
          <p>Created By: <strong>{props.groceryData.createdBy}</strong></p>
          <p> Purchased? : {props.groceryData.purchased?<strong>Yes</strong>:<strong>No</strong>} </p>
        </FormGroup>
        </div>
          </div>
          <FormGroup>
              <Button type="submit" color="success" size="large" block>Submit</Button>
          </FormGroup>
    </form>
  );
}
export default GroceryEditForm;