import React from 'react';
import { ListGroup, ListGroupItem,Button, Row, Col } from 'reactstrap';
import '../../../src/App.css';






export default class TaskList extends React.Component{

    taskColor(task){
        let status;
        let createdDate = new Date(task.submittedDate);
        createdDate = createdDate.getTime();
        let endDate = Date.now();
        
        const daysElapsed = (createdDate, endDate) => {
          
            const millisecondsPerDay = 24 * 60 * 60 * 1000;
            return  Math.round(Math.abs(endDate - createdDate) / millisecondsPerDay);
        }

        if(task.completedFlag){
            status = "success";  
        }
        else if(daysElapsed(createdDate, endDate) <= 2 && !task.completedFlag )
        {
            status = "info";
        }
        else if(daysElapsed(createdDate, endDate) >= 3 && daysElapsed(createdDate, endDate) <= 6 && !task.completedFlag)
        {
            status = "warning";
        }
        else if(daysElapsed(createdDate, endDate) > 6 && !task.completedFlag)
        {
            status = "danger";
        }
    
        return status;
    }

    deleteTask(task) {
        this.props.childCallBack(task);
    }

    updateCompleted(task) {
        this.props.childUpdate(task);
    }

    render() {

       
        const tasks = this.props.tasks;
 

        return(
            <div id="taskListBox">
              {!tasks &&
                <p>Loading tasks....</p>
              }

              {tasks.length <= 0 &&
                <p className="text-center">There are no Tasks!!! </p>
              }

              {tasks && tasks.length > 0 &&

              <ListGroup>
                {tasks.map((task, i) => 
                <ListGroupItem key = {i} color = {this.taskColor(task)} tag="button" onClick = {() => this.updateCompleted(task)}> 
                <Row>
                <Col></Col>
                   {task.completedFlag === true? <Col><h5 id="tasksDone">{task.task} </h5> </Col>: <Col><h5 id="tasks">{task.task} </h5> </Col> }
                    <Col>
                  
                    </Col>
                </Row>

                {/* <h5 id="tasks">{task.task} </h5> */}
               
                </ListGroupItem> )}
                <Button className="buttonGroup" onClick = {() => this.deleteTask()}>Clear Done</Button>
                </ListGroup>
                
              }
            </div>

        )
    }

}