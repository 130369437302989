import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import logo from '../../images/icons/OtaskuLogo2.png'
import '../../App.css';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';


export default class LogoLogin extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);


        this.state = {
        user: null,
        dropdownOpen: false
      };
    }

    componentDidMount() {
      this.fetchUser();
    }
   
    async fetchUser() {
      let user = await Auth.currentAuthenticatedUser();
      this.setState({ user: user.username });
    }

    toggle() {
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen
      }));
    }

    customSignOut = () => {
      Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
  }

render() {

  let user = this.state.user;
        
  return(
      <div className="appBody">
              <a href="/"> <img id="App-logo" src={logo} alt="My logo" /></a>
           
            <div id="profile">
            <UncontrolledDropdown size="sm">
                {user && 
                <DropdownToggle id="loginCss">
                 Hi { user}
                </DropdownToggle>
                }

                <DropdownMenu center="true">
                { user && <DropdownItem>
                  
               <a onClick={this.customSignOut}>Sign Out</a>
                 
                </DropdownItem>}

                { !user && <DropdownItem>
                  
                  Loading...
                    
                   </DropdownItem>}
   


                </DropdownMenu>


              </UncontrolledDropdown>  
      </div>
    </div>
  );

       
    }



}