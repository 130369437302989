import React from 'react';
import { Col, Row, Modal, Button, ModalHeader, 
    ModalBody, ModalFooter, Alert, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import '../../App.css'; 
import SagiriMoods from './SagiriMoods';
import TaskList from './TaskList';
import uuid from 'uuid';
import { API, Auth } from "aws-amplify";


export default class Tasks extends React.Component {
    constructor(props){
        super(props);
        this.showDeleteModal = this.showDeleteModal.bind(this);  
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.confirmDeleteTask = this.confirmDeleteTask.bind(this);
        this.toggleAdd = this.toggleAdd.bind(this);
        this.addTask = this.addTask.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        

    this.state = {
        tasks: [
            // {taskId: uuid(), task: 'Finish Projects', submittedDate: '2018-09-04T19:31:38.514Z', owner: 'kevin', completedFlag: false},
            // {taskId: uuid(), task: 'Watch Anime', submittedDate: '2018-10-21T19:31:38.514Z', owner: 'kevin', completedFlag: false},
            // {taskId: uuid(), task: 'Write Story', submittedDate: '2018-10-17T19:31:38.514Z', owner: 'testUser1', completedFlag: false},
            // {taskId: uuid(), task: 'Sleep', submittedDate: '2018-09-21T19:31:38.514Z', owner: 'testUser1', completedFlag: true}
        ],
        isLoading: false,
        showAddTask: false,
        showDeleteModal: false,
        disableDeleteModal: false,
        taskToDelete: null,
        taskToAdd: null,
        user: null
    }

}



componentDidMount(){
    this.fetchList();
}


///// Get Request/////
async fetchList() {

    let user = await Auth.currentAuthenticatedUser()
    this.setState({ user: user.username });

    let myInit = { 
        headers: {}, 
        response: false,
        queryStringParameters: {  
            owner: user.username
        }
       
    }
    await API.get("tasksApi", "/tasks", myInit)
      .then((res) => {
        this.setState({ tasks: [...res] });
        }).catch(err =>{
          console.log(err + err.data)
        });
  }


//Add Task//

toggleAdd() {
    this.setState({
        task: this.state.task,
        showAddTask: !this.state.showAddTask
       })
}

async addTask(task) {

    let currentDate = new Date();
    let isoDate = new Date(currentDate).toISOString()
    let user = this.state.user;
    let taskId = uuid();

    // Ideally we'd have the database callback return the value... To-do-Someday...
    let newTask =  {taskId: taskId, task: task, submittedDate: isoDate, owner: user, completedFlag: false};
    
    await API.post("tasksApi", "/tasks", {
        body: {
            taskId: newTask.taskId,
            task: newTask.task,
            submittedDate: newTask.submittedDate,
            owner: newTask.owner,
            completedFlag: newTask.completedFlag
        }
    })
        .then((res) => {
        this.setState({tasks: [...this.state.tasks, newTask]})
        }).catch(err =>{
        });
  
}

onSubmit(e) {
  
    const input = document.getElementById('inputBox');
    let data = input.value;
   
    
    if(data !== ""){
        this.addTask(data); 
        e.target.value = ""
       
    }
    else
    {
        alert("Please enter data!")
    } 
}

onKeyPress = (e) => {
    if(e.which === 13) {
      this.onSubmit(e);
    }
  }




// Delete Tasks
showDeleteModal(task) {
    this.setState({ 
        showDeleteModal: true,
        taskToDelete: task                                    
    })
}

hideDeleteModal() {
   this.setState({ showDeleteModal: false})
}

async confirmDeleteTask() {
   
    let tasks = this.state.tasks;
    const functionWithPromise = task => { 
        if(task.completedFlag === true)
        {
            API.del("tasksApi", "/tasks", {
                body: {
                     taskId: task.taskId,
                     submittedDate: task.submittedDate
                      }
              })
        }
        return Promise.resolve('ok')
      }
      
      await Promise.all(tasks.map(async (task) => {
        await functionWithPromise(task);
    })).then(() => {
        let tasks = this.state.tasks;
        let newList = tasks.filter(task => task.completedFlag === false);
        this.setState({tasks: newList});
    })
    .catch(err => {
        console.log(err);
    })
    .finally(()=> {
        this.hideDeleteModal();
    })
    
}

//Update Completed State///

async updateStatus(task) {

    let tasks = this.state.tasks;
   

    await API.put("tasksApi", "/tasks", {
        body: {
            taskId: task.taskId,
            task: task.task,
            submittedDate: task.submittedDate,
            owner: task.owner,
            completedFlag: !task.completedFlag
        }
        }).then((res) => {
            let newList = tasks.map(item => {
            if(item.taskId === task.taskId){
                item.completedFlag = !item.completedFlag;
                return item;
            }
            else return item;
        });
            this.setState({tasks: newList})
        }).catch(err =>{
          console.log(err)
        });
        
}


// Extras //

//Child Callbacks//
childCallBack = (keyFromChild) => {
    this.showDeleteModal(keyFromChild);
}

childUpdate = (keyFromChild) => {
    this.updateStatus(keyFromChild);
}

render(){
    
    const taskState = this.state
    const tasks = this.state.tasks;
    
    return(
        <div className="comBody">
            <h2 className="App">Tasks</h2>
            <div>

                <div id="Displays">
                    <Row>
                        <Col col="auto" >
                        <SagiriMoods tasks = {tasks}  />
                        </Col>

                        <Col col="auto">
                        <TaskList tasks = {tasks} childCallBack={this.childCallBack} childUpdate={this.childUpdate}  />
                        </Col>
                    </Row>
                </div>
               
            <br></br>

               <div id="Displays">
                
                {
                    <div>
                        <Row>
                            <Col></Col>
                            <Col>
                                <InputGroup id="addTaskBox" >
                                <Input placeholder="Add New Task" type="text" name="task" id="inputBox" onKeyPress={this.onKeyPress.bind(this)} />
                                <InputGroupAddon addonType="append"><Button color="success" type="submit" onClick={this.onSubmit}   >Submit</Button></InputGroupAddon>
                                {/* <Button color="secondary" onClick={() => this.toggleAdd()}  >Close</Button> */}
                                </InputGroup>
                                
                            </Col>
                            <Col></Col>
                        </Row>
                       
                    </div>
                }

              
                </div>
            </div>

    {/* Modal for deleting grocery */}
    <Modal
    isOpen={taskState.showDeleteModal}
    toggle={this.hideDeleteModal}
    container={this}
    className="contained-modal-title"
  >
    <ModalHeader> Delete This Task?</ModalHeader>
    <ModalBody>
    {taskState.taskToDelete && !taskState.isLoading &&
      <Alert color="warning">
 Are you sure you want to delete this task: <strong>{taskState.taskToDelete.task} </strong> ?
</Alert>
    }

{!taskState.taskToDelete && !taskState.error && !taskState.isLoading &&
      <Alert color="warning"> <p>Clear <strong>ALL</strong> completed tasks?</p> </Alert>
    }
    </ModalBody>
    <ModalFooter>
     {!taskState.isLoading &&
       <div>
       <Button onClick={this.confirmDeleteTask}>Yes</Button>
       <Button onClick={this.hideDeleteModal}>No</Button>
       </div>
    }

    </ModalFooter>
  </Modal>


        </div>
    )
}

}