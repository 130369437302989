import React, { Component } from "react";
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createBrowserHistory } from "history";
import Amplify, {Auth} from "aws-amplify";
import aws_exports from "./aws-exports";
import { withAuthenticator } from 'aws-amplify-react';
import './App.css';
import LogoLogin from "./components/navigationComponents/LogoLogin";
import Navigation from './components/navigationComponents/Navigation';

import Home from './components/home/Home';
import Tasks from "./components/tasks/Tasks";
import Groceries from "./components/grocery/Groceries";
import Anime from "./components/anime/Anime";

import SampleHome from './sampleComponents/SampleHome';
import SampleTasks from "./sampleComponents/SampleTasks";
import SampleGroceries from "./sampleComponents/SampleGroceries";
import SampleAnime from "./sampleComponents/SampleAnime";





Amplify.configure(aws_exports);
const history = createBrowserHistory()



class App extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
        user : null
    }
   
  }

  


  render() {

  

    if (this.props.authState === "signedIn") {
    
      if(this.props.authData.username === "sampleUser") {

        return (
          <div className="appBody" >
            <LogoLogin></LogoLogin>
            <Navigation></Navigation>
            <Router history={history}>
              <Route exact path = "/" component={SampleHome}/>
              <Route path="/tasks" component= {SampleTasks} />
              <Route path="/groceries" component={SampleGroceries} />
              <Route path="/anime" component={SampleAnime} />
            </Router> 
          </div>
        ); 
      }

      else 
      {
        return (
          <div className="appBody" >
            <LogoLogin></LogoLogin>
            <Navigation></Navigation>
            <Router history={history}>
              <Route exact path = "/" component={Home}/>
              <Route path="/tasks" component= {Tasks} />
              <Route path="/groceries" component={Groceries} />
              <Route path="/anime" component={Anime} />
            </Router> 
          </div>
        ); 
      }
    }
    else {
      return null;
    }

  }
}



//CHANGE THIS BACK KEVIN 
export default App;

