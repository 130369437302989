import React, {Component} from 'react';
import { Col, Row, Jumbotron } from 'reactstrap';


//import DashboardComponent from '../homeComponents/DashboardComponent'
import sagiri from '../../images/sagiriSlide.png';
import anime from '../../images/animeSlide.png';
import groceriesSlide from '../../images/groceriesKannaSlide.png';
import CarouselComponent from '../sharedComponents/CarouselComponent';
import HomeProgress from './HomeProgress';
import { API, Auth } from "aws-amplify";

export default class Home extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      tasks: [
        // {taskId: "1", task: 'Finish Projects', createdDate: '2019-05-04T19:31:38.514Z', owner: 'kevin', completedFlag: false},
        // {taskId: "2", task: 'Watch Anime', createdDate: '2019-06-12T19:31:38.514Z', owner: 'kevin', completedFlag: false},
        // {taskId: "3", task: 'Write Story', createdDate: '2019-06-09T19:31:38.514Z', owner: 'testUser1', completedFlag: true},
        // {taskId: "4", task: 'Sleep', createdDate: '2019-06-13T19:33:38.514Z', owner: 'testUser1', completedFlag: true},
        // {taskId: "5", task: 'Sleep', createdDate: '2019-06-08T19:33:38.514Z', owner: 'testUser1', completedFlag: false}
      ],
      groceries: [
        // {
        //   "quantity": "1",
        //   "groceryName": "Milk",
        //   "groceriesId": "45bd8440-87b1-11e9-8cbc-0db2af470f25",
        //   "purchased": true,
        //   "createdBy": "testUser",
        //   "assignedTo": "testUser"
        // },
        // {
        //   "quantity": "2",
        //   "groceryName": "eggs",
        //   "groceriesId": "063f7ce0-791c-11e9-a6a8-67b5172612c2",
        //   "purchased": false,
        //   "createdBy": "testUser",
        //   "assignedTo": "kevin"
        // },
        // {
        //   "quantity": "3",
        //   "groceryName": "test",
        //   "groceriesId": "af7e28c0-87b2-11e9-9724-1398fd9a5d07",
        //   "purchased": false,
        //   "createdBy": "testUser",
        //   "assignedTo": "kevin"
        // },
        // {
        //   "quantity": "1",
        //   "groceryName": "cheese",
        //   "groceriesId": "84645610-72c6-11e9-a476-27b8ba2340fe",
        //   "purchased": true,
        //   "createdBy": "testUser",
        //   "assignedTo": "kevin"
        // },
        // {
        //   "quantity": "1",
        //   "groceryName": "cake",
        //   "groceriesId": "730d78f0-8735-11e9-9b95-231d91f6e2b3",
        //   "purchased": true,
        //   "createdBy": "testUser",
        //   "assignedTo": "testUser"
        // },
        // {
        //   "quantity": "1",
        //   "groceryName": "test",
        //   "groceriesId": "5db86430-87b0-11e9-b346-4129f1c70640",
        //   "purchased": true,
        //   "createdBy": "testUser",
        //   "assignedTo": "testUser"
        // }
      ],
      bookmarks: [
        // {
        //     "title": "1",
        //     "initDateReleased": "10/01/2019",
        //     "url": "google.com",
        //     "episodes": 10,
        //     "episodesWatched": 0
        // },
        // {
        //     "title": "2",
        //     "initDateReleased": "10/01/2019",
        //     "url": "google.com",
        //     "episodes": 5,
        //     "episodesWatched": 3
        // },
        // {
        //     "title": "3",
        //     "initDateReleased": "10/01/2019",
        //     "url": "google.com",
        //     "episodes": 12,
        //     "episodesWatched": 12
            
        // },
        // {
        //     "title": "4",
        //     "initDateReleased": "10/01/2019",
        //     "url": "google.com",
        //     "episodes": 12,
        //     "episodesWatched": 12
        // },
 
    ],
      isLoading: true
    };
  }

    componentDidMount(){
      this.fetchData();
    }
  
    
//////////////////// FETCH ALL DATA //////////////////


async fetchData() {
  this.setState({isLoading:true});
  await Auth.currentAuthenticatedUser()
    .then (res => {
    this.fetchGroceries(res.username);
    return res;

  }).then( (res) => {
      this.fetchTasks(res.username);
      return res;

  }).then( (res) => {
        this.fetchBookmarks(res.username);
      
  }).catch( error => { console.log(error); 
  }).finally( () => { this.setState({isLoading:false}) });
}


  
    // Get Functions //

   ///// Get Groceries/////
async fetchGroceries(user) {

  let myInit = { // OPTIONAL
    headers: {}, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        createdBy: user
    }
  }

  await API.get("groceriesApi", "/groceries", myInit)
  .then((res) => {
    this.setState({ groceries: [...res] });
    //console.log(res)
    }).catch(err =>{
      console.log(err + err.data)
    });
 
}

///// Get Tasks/////
async fetchTasks(user) {

  let myInit = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {  // OPTIONAL
          owner: user
      }
     
  }
  await API.get("tasksApi", "/tasks", myInit)
    .then((res) => {
      this.setState({ tasks: [...res] });
      //console.log(res)
      }).catch(err =>{
        console.log(err + err.data)
      });
}

// Get Bookmarks //
async fetchBookmarks(user) {

  let myInit = { // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {  // OPTIONAL
          bookmarkedBy: user
      }
  }
      
  await API.get("animeApi", "/anime", myInit)
      .then((res) => {
          this.setState({ bookmarks: [...res] });
          
      }).catch(err =>{
          console.log(err + err.data)
      });

}






    

render(){
    const width = "500px";
    const background = "black";
    const items = [
        {
          src: sagiri,
          altText: 'Tasks with Sagiri',
          caption: "Let's get those tasks done!",
          captionHeader: 'Tasks with Sagiri',
          link: "/tasks"
        },
        {
          src: groceriesSlide,
          altText: 'Groceries with Kanna',
          caption: "In case you forget!",
          captionHeader: 'Groceries with Kanna',
          link: "/groceries"
        },
        {
          src: anime,
          altText: 'Anime Bookmarks',
          caption: "We'll help you manage your addicti-...I mean hobby",
          captionHeader: 'Bookmarks with Waifus',
          link: "/anime"
        }
       
      ];

    return(
        <div className="container">
            
            <Jumbotron id="jumbo">
            <h1 className="jumboGreeting">Welcome Home!</h1>
            <p >We have prepared all of your reminders below. </p>
            </Jumbotron>


            <div id="Displays">
                    <Row>
                        <Col col="auto" >
                        <CarouselComponent items={items} width={width} isActive={true} backgroundColor={background}/>
                        </Col>

                        <Col col="auto">
                        
                        { 
                          this.state.isLoading === true? 
                          <div id="homeProgress">Loading...</div> : 
                          <HomeProgress groceries ={this.state.groceries} tasks={this.state.tasks} bookmarks={this.state.bookmarks} /> 
                        }

                        {/* <HomeProgress groceries ={this.state.groceries} tasks={this.state.tasks} bookmarks={this.state.bookmarks} />  */}
                        </Col>
                    </Row>
                </div>
               
            <br></br>

           
           
          

           
























        </div>

        
       














        );


}



}

