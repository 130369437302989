const date = new Date();
const currentYear = date.getFullYear();
const month = date.getMonth() + 1;

const currentSeason = () => {
    switch(month)
    {
        case 1:
        case 2:
        case 3:
        return "winter";

        case 4:
        case 5:
        case 6:
        return "spring";
        
        case 7:
        case 8:
        case 9:
        return "summer";

        case 10:
        case 11:
        case 12:
        return "fall";

        default:
            return "spring";  
    }
}


export const animeSeasonApiUrl = `https://api.jikan.moe/v3/season/${currentYear}/${currentSeason()}`;



export const calculateCurrentEpisode = (initDate) => {
    
    let currentDate = Date.now();
    let newInitDate = new Date(initDate);
    let diff = (new Date(currentDate).getTime() - new Date(newInitDate).getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    const episodeNumber = Math.abs(Math.round(diff));
    return episodeNumber;
}

export const watchStatus = (initDate, totalEpisodes, totalWatched ) => {
    let currentEpisodeNum = calculateCurrentEpisode(initDate);
    let status;

    if(totalEpisodes !== null && totalWatched>=totalEpisodes)
    {
        status = "Completed"
    }
    else if(totalWatched===currentEpisodeNum)
    {
        status = "Watched"
    }
    else status = "Unwatched"

    return status;
}

//Filters the Apilist so that it doesn't show anime already Bookmarked
export const filteredApi = (value, animeList) => {
    let filteredKeyword;
    //const animeServerTitles = animeFromServer.map(value => value.title);
    const animeTitle = value.title;

    //animeServerTitle !== undefined && animeServerTitle.length !== 0 
    !animeList.includes(animeTitle) ? filteredKeyword = value.title
    : filteredKeyword = null
    return filteredKeyword;
}

export const sortAnime = (array) => {
    // sort by name
    array.sort(function(a, b) {
    var nameA = a.title.toUpperCase(); // ignore upper and lowercase
    var nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  
    // names must be equal
    return 0;
  });

  return array;
}

export const groupAnime = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }



///////Add Anime//////




/////DELETE IMPORTED ANIME LIST FROM SERVER "Imported Animes"

export const deleteAllBookmarksRequest = () => {
   
}

export const deleteAllAnime = () => {
  
                
}


export const showDeleteModal = (anime) => {
    this.setState({ showDeleteModal:true });
}

export const hideDeleteModal = () => {
    this.setState({ showDeleteModal:false });
}



////Edit Anime


export const updateWatchedEpisode = (anime, currentNumber) => {
   
    
}

