import React, { Component } from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink
   } from 'reactstrap';
  import '../../App.css';
  import { SignOut } from 'aws-amplify-react';
  import { Auth } from 'aws-amplify';





class Navigation extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
          user: null,
          isOpen: false
        };
      }

      componentDidMount() {
       
        this.fetchUser();
      }
     
      async fetchUser() {
        let user = await Auth.currentAuthenticatedUser();
        this.setState({ user: user.username })
     
      }
      
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    customSignOut = () => {
        Auth.signOut()
          .then(data => console.log(data))
          .catch(err => console.log(err));
    }
     

 render() {
  
    // let user = this.state.user;
    // let logIn = "Login";

    return (

      <div className = "comBody">
        <Navbar color="dark" expand="md" className="navbar-dark">
          {/* <NavbarBrand href="/">Otasku</NavbarBrand> */}
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mx-auto" navbar >
 
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>

            <NavItem>
                <NavLink href="/tasks">Tasks</NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="/groceries">Groceries</NavLink>
            </NavItem>


             <NavItem>
                <NavLink href="/anime">Anime</NavLink>
            </NavItem>

            </Nav>
          </Collapse>
         
        </Navbar>

        </div>
    )
 }
}

 export default Navigation;